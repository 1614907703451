<template>
  <div>
    <Pane />

    <div class="container">
      <div class="list">
        <a-input-search placeholder="请输入关键词搜索" v-model="searchValue" @search="onSearch">
          <a-button type="primary" slot="enterButton"> 搜索 </a-button>
        </a-input-search>
        <Padding />
        <a-checkbox v-model="selected"> 未配置 </a-checkbox>
        <Padding />

        <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }" :row-selection="{
                type: 'radio',
                selectedRowKeys,
                onChange: onSelectChange,
              }">
          <a-table-column title="序号" width="50px">
            <template slot-scope="text, row, index">
              {{ index + 1 }}
            </template>
          </a-table-column>
          <a-table-column title="设计编号" width="140px">
            <template slot-scope="text">
              <span style="color: #1890ff">{{ text.code }}</span>
            </template>
          </a-table-column>
          <a-table-column title="合同名称" data-index="name" />
          <a-table-column title="合同金额(万元)" align="right" width="100px">
            <template slot-scope="text">
              <span style="color: #1890ff">
                <Money :money="text.contractAmount" />
              </span>
            </template>
          </a-table-column>

          <a-table-column title="收款项" align="center" width="60px">
            <template slot-scope="text">
              <span v-if="text.receiptConfigured === 1">
                <a-icon type="check" style="color: #1890ff; font-size: 18px" />
              </span>
            </template>
          </a-table-column>
        </a-table>
      </div>

      <div class="contract-name">
        <img src="../../assets/icon-contract.png" alt="" />
        <template v-if="activeObj.name">
          <span>{{ activeObj.name }}</span><span>(
            <Money :money="activeObj.contractAmount" />万)
          </span>
        </template>
      </div>
      <div class="form" v-if="activeObj.name">
        <div class="right">
          <a-button type="primary" @click="addText">新增</a-button>
        </div>

        <Padding />

        <a-table bordered :dataSource="collectionList" :pagination="false">
          <a-table-column title="编号" align="center">
            <template slot-scope="text">
              <div>
                <a-input v-if="text.editable" v-model="text.code" size="small" style="width: 40px" />
                <span v-else>{{ text.code }}</span>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="收款项名称">
            <template slot-scope="text">
              <div>
                <a-textarea v-if="text.editable" v-model="text.name" size="small" />
                <span v-else>{{ text.name }}</span>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="收款比例" align="right">
            <template slot-scope="text">
              <div>
                <a-input-number :min="0.1" :max="100" size="small" v-if="text.editable" v-model="text.ratio"
                  style="width: 60px" />
                <span v-else>{{ text.ratio }}%</span>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="收款金额(万元)" align="right">
            <template slot-scope="text">
              <Money :money="text.planReceipt" />
            </template>
          </a-table-column>

          <a-table-column title="进度确认" align="center">
            <template slot-scope="text">
              <a-checkbox v-model="text.isFinished" :disabled="!text.editable" />
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center" width="120px">
            <template slot-scope="text, record, index">
              <a-space>
                <a href="#" v-if="!text.editable" @click.prevent="editText(index)">编辑</a>
                <a href="#" v-if="text.editable" @click.prevent="saveText(index)">保存</a>
                <a href="#" class="danger" @click.prevent="removeText(index)">删除</a>
              </a-space>
            </template>
          </a-table-column>
        </a-table>

        <Padding />
        <div class="center">
          <a-button type="primary" @click="submit" :loading="saveLoading">提交</a-button>
        </div>
      </div>
      <div class="form" v-else>
        <a-empty description="请选择合同" />
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList, fetchDetail, save } from "@/api/contract/collection";
export default {
  mixins: [watermark],

  data() {
    return {
      searchValue: "",
      selected: false, // 默认查询所有，勾选上查询未配置

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      selectedRowKeys: [],
      activeObj: {},

      collectionList: [],
      saveLoading: false,
    };
  },

  watch: {
    // 切换配置条件
    selected() {
      this.getList();
    },
  },

  mounted() {
    this.getList();

    // 设置水印
    const list = document.querySelector(".list");
    const form = document.querySelector(".form");
    if (list && form) {
      this.setWatermark(list);
      this.setWatermark(form);
    }
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        keyWord: this.searchValue ? this.searchValue.trim() : undefined,
        receiptConfigured: this.selected ? 0 : undefined,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list.map((item) => {
              return {
                ...item,
                key: item.id,
              };
            });
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onSearch() {
      this.current = 1;
      this.getList();
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    onSelectChange(keys) {
      this.selectedRowKeys = keys;
      fetchDetail({
        id: keys[0],
      }).then((res) => {
        this.activeObj = res;
        if (Array.isArray(res.planReceiptList)) {
          if (!res.receiptConfigured) {
            // 未在此页面配置过
            this.collectionList = res.planReceiptList.map((item, index) => {
              return {
                ...item,
                code: String(index + 1),
                ratio: (item.planReceipt / res.contractAmount) * 100,
                isFinished: !!item.isFinished,
              };
            });
          } else {
            this.collectionList = res.planReceiptList.map((item) => {
              return {
                ...item,
                isFinished: !!item.isFinished,
              };
            });
          }
        }
      });
    },

    addText() {
      this.collectionList.push({
        editable: true,
      });
    },
    editText(index) {
      this.collectionList.splice(index, 1, {
        ...this.collectionList[index],
        editable: true,
      });
    },
    saveText(index) {
      const item = this.collectionList[index];
      if (!item.code) {
        this.$message.error("请填写编号");
        return;
      }
      if (!item.name) {
        this.$message.error("请填写收款项名称");
        return;
      }
      if (!item.ratio) {
        this.$message.error("请填写收款比例");
        return;
      }
      this.collectionList.splice(index, 1, {
        ...item,
        planReceipt: this.activeObj.contractAmount * item.ratio * 0.01,
        editable: false,
      });
    },
    removeText(index) {
      console.log("index", index);
      this.collectionList.splice(index, 1);
    },

    submit() {
      let isEditing = false;
      this.collectionList.forEach((item) => {
        if (item.editable) {
          isEditing = true;
        }
      });
      if (isEditing) {
        this.$message.error("请先保存数据");
        return;
      }

      let sum = 0;
      this.collectionList.forEach((item) => {
        sum += item.ratio;
      });
      if (sum !== 100) {
        this.$message.error("收款比例必须等于100%");
        return;
      }

      this.saveLoading = true;

      let receiptList = [...this.collectionList].sort((x, y) => {
        if (x.code > y.code) {
          return 1;
        } else if (x.code < y.code) {
          return -1;
        } else {
          return 0;
        }
      });

      save({
        contractId: this.activeObj.id,
        receiptList: receiptList.map((item) => {
          return {
            ...item,
            isFinished: item.isFinished ? 1 : 0,
          };
        }),
      })
        .then(() => {
          this.getList();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  grid-template-rows: 48px 1fr;

  &>div {
    background-color: #fff;
    padding: 12px;
    box-sizing: border-box;
  }

  .list {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .contract-name {
    font-size: 14px;
    font-weight: bold;
    color: #1890ff;
    display: flex;
    align-items: center;

    img {
      width: 15px;
      margin-right: 8px;
    }
  }
}

.form {
  .item {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;

    .label {
      width: 10em;

      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }

    .value {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .btn {
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }
}
</style>