import request from '../request'

export function fetchList(data) {
    return request({
        url: '/project-service/contract/temporary/list',
        data,
        method: 'post'
    })
}

export function fetchDetail(params) {
    return request({
        url: '/project-service/contract/query/' + params.id,
    })
}

export function save(data) {
    return request({
        url: '/project-service/contract/temporary/receipt',
        method: 'POST',
        data
    })
}